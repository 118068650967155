import React from 'react';
import { Row, Col } from 'antd';
import ProductCard from './ProductCard.tsx';
import { useTranslation } from 'react-i18next';

interface Product {
  _id: number;
  name: string;
  price: number;
  image: string;
  images: string[];
  colour: string;
  description: string;
  isDiscounted?: boolean;
  nutrition?: {
    servingSize: string;
    calories: number;
    totalFat: string;
    totalFatPercent: string;
    saturatedFat: string;
    saturatedFatPercent: string;
    cholesterol: string;
    cholesterolPercent: string;
    sodium: string;
    sodiumPercent: string;
    totalCarbohydrate: string;
    totalCarbohydratePercent: string;
    protein: string;
    proteinPercent: string;
  };
}

interface ProductListProps {
  products: Product[];
  onAddToCart: (id: number) => void;
}

const ProductList: React.FC<ProductListProps> = ({ products, onAddToCart }) => {
  const { t } = useTranslation();
  return (
    <Row gutter={[16, 16]}>
      {products.map((product) => (
        <Col key={product._id} xs={24} sm={12} md={8} lg={6}>
          <ProductCard
            {...product}
            onAddToCart={() => onAddToCart(product._id)}
          />
        </Col>
      ))}
    </Row>
  );
};

export default ProductList;
