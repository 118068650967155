// AddProduct.tsx
import React, { useState } from 'react';
import axios from 'axios';
import { Box, Typography, TextField, Grid, Button } from '@mui/material';
import { Checkbox } from '@mui/material'; // import Checkbox


const BASE_URL = 'http://localhost:5001'; // Adjust this URL as per your API configuration
const API_URL = process.env.REACT_APP_API_URL || 'https://staging.popcornas.lt';
const AddProduct: React.FC = () => {
  const [newProduct, setNewProduct] = useState({
    name: '',
    price: '',
    images: '',
    description: '',
    category: '',
    composition: '',
    nutrition: {
      servingSize: '',
      calories: '',
      caloriesKj: '',
      totalFat: '',
      totalFatPercent: '',
      saturatedFat: '',
      saturatedFatPercent: '',
      cholesterol: '',
      cholesterolPercent: '',
      sodium: '',
      sodiumPercent: '',
      totalCarbohydrate: '',
      totalCarbohydratePercent: '',
      dietaryFiber: '', // Added field
      dietaryFiberPercent: '', // Added field
      sugars: '', // Added field
      sugarsPercent: '', // Added field
      protein: '',
      proteinPercent: '',
      vitaminD: '', // Added field
      vitaminDPercent: '', // Added field
      calcium: '', // Added field
      calciumPercent: '', // Added field
      iron: '', // Added field
      ironPercent: '', // Added field
      potassium: '', // Added field
      potassiumPercent: '', // Added field
      salt:'',
      lactose: '',
      fiber:'',
    },
    isDiscounted: false,
    discountedPrice: '',
  });

  const formatDescription = (description: string) => {
    return description.split('<br>').map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));
  };

  const addProduct = async () => {
    try {
      await axios.post(`${API_URL}/api/products`, newProduct);
      alert('Product added successfully');
      // Reset form after submission
      setNewProduct({
        name: '',
        price: '',
        images: '',
        description: '',
        category: '',
        composition: '',
        nutrition: {
          servingSize: '',
          calories: '',
          caloriesKj: '',
          totalFat: '',
          totalFatPercent: '',
          saturatedFat: '',
          saturatedFatPercent: '',
          cholesterol: '',
          cholesterolPercent: '',
          sodium: '',
          sodiumPercent: '',
          totalCarbohydrate: '',
          totalCarbohydratePercent: '',
          dietaryFiber: '',
          dietaryFiberPercent: '',
          sugars: '',
          sugarsPercent: '',
          protein: '',
          proteinPercent: '',
          vitaminD: '',
          vitaminDPercent: '',
          calcium: '',
          calciumPercent: '',
          iron: '',
          ironPercent: '',
          potassium: '',
          potassiumPercent: '',
          salt:'',
          lactose: '',
          fiber:'',
        },
        isDiscounted: false,
    discountedPrice: ''
      });
    } catch (error) {
      console.error('Error adding product:', error.response || error.message);
      alert(`Error adding product: ${error.response?.data?.message || error.message}`);
    }
  };

  const handleDiscountToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewProduct({
      ...newProduct,
      isDiscounted: event.target.checked,
      discountedPrice: event.target.checked ? newProduct.discountedPrice : '', // Clear discounted price if unchecked
    });
  };

  return (
    <Box>
      <Typography variant="h5" component="div" sx={{ textAlign: 'center', margin: 2 }}>
        Add New Product
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Product Name"
            value={newProduct.name}
            onChange={(e) => setNewProduct({ ...newProduct, name: e.target.value })}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Price"
            value={newProduct.price}
            onChange={(e) => setNewProduct({ ...newProduct, price: e.target.value })}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Image URLs (comma-separated)"
            value={newProduct.images}
            onChange={(e) => setNewProduct({ ...newProduct, images: e.target.value })}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Description"
            value={newProduct.description}
            onChange={(e) => setNewProduct({ ...newProduct, description: e.target.value })}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Description Preview: add br html tags to linebreak`</Typography>
          <Typography component="div">
            {formatDescription(newProduct.description)}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Category"
            value={newProduct.category}
            onChange={(e) => setNewProduct({ ...newProduct, category: e.target.value })}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Composition"
            value={newProduct.composition}
            onChange={(e) => setNewProduct({ ...newProduct, composition: e.target.value })}
            fullWidth
          />
        </Grid>

        {/* Nutrition Information - All Fields Included */}
        <Grid item xs={12} sm={6}>
          <TextField
            label="Serving Size"
            value={newProduct.nutrition.servingSize}
            onChange={(e) => setNewProduct({ ...newProduct, nutrition: { ...newProduct.nutrition, servingSize: e.target.value } })}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="CaloriesKcal"
            value={newProduct.nutrition.calories}
            onChange={(e) => setNewProduct({ ...newProduct, nutrition: { ...newProduct.nutrition, calories: e.target.value } })}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="CaloriesKj"
            value={newProduct.nutrition.caloriesKj}
            onChange={(e) => setNewProduct({ ...newProduct, nutrition: { ...newProduct.nutrition, caloriesKj: e.target.value } })}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Fat"
            value={newProduct.nutrition.totalFat}
            onChange={(e) => setNewProduct({ ...newProduct, nutrition: { ...newProduct.nutrition, totalFat: e.target.value } })}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Total Fat (%)"
            value={newProduct.nutrition.totalFatPercent}
            onChange={(e) => setNewProduct({ ...newProduct, nutrition: { ...newProduct.nutrition, totalFatPercent: e.target.value } })}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Saturated Fat IS KURIU SOCIUJU"
            value={newProduct.nutrition.saturatedFat}
            onChange={(e) => setNewProduct({ ...newProduct, nutrition: { ...newProduct.nutrition, saturatedFat: e.target.value } })}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Saturated Fat (%)"
            value={newProduct.nutrition.saturatedFatPercent}
            onChange={(e) => setNewProduct({ ...newProduct, nutrition: { ...newProduct.nutrition, saturatedFatPercent: e.target.value } })}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Total sugar"
            value={newProduct.nutrition.cholesterol}
            onChange={(e) => setNewProduct({ ...newProduct, nutrition: { ...newProduct.nutrition, cholesterol: e.target.value } })}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Total sugar"
            value={newProduct.nutrition.cholesterolPercent}
            onChange={(e) => setNewProduct({ ...newProduct, nutrition: { ...newProduct.nutrition, cholesterolPercent: e.target.value } })}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Sodium"
            value={newProduct.nutrition.sodium}
            onChange={(e) => setNewProduct({ ...newProduct, nutrition: { ...newProduct.nutrition, sodium: e.target.value } })}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Sodium (%)"
            value={newProduct.nutrition.sodiumPercent}
            onChange={(e) => setNewProduct({ ...newProduct, nutrition: { ...newProduct.nutrition, sodiumPercent: e.target.value } })}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Total Carbohydrate"
            value={newProduct.nutrition.totalCarbohydrate}
            onChange={(e) => setNewProduct({ ...newProduct, nutrition: { ...newProduct.nutrition, totalCarbohydrate: e.target.value } })}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Total Carbohydrate (%)"
            value={newProduct.nutrition.totalCarbohydratePercent}
            onChange={(e) => setNewProduct({ ...newProduct, nutrition: { ...newProduct.nutrition, totalCarbohydratePercent: e.target.value } })}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Dietary Fiber"
            value={newProduct.nutrition.dietaryFiber}
            onChange={(e) => setNewProduct({ ...newProduct, nutrition: { ...newProduct.nutrition, dietaryFiber: e.target.value } })}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Dietary Fiber (%)"
            value={newProduct.nutrition.dietaryFiberPercent}
            onChange={(e) => setNewProduct({ ...newProduct, nutrition: { ...newProduct.nutrition, dietaryFiberPercent: e.target.value } })}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Sugars"
            value={newProduct.nutrition.sugars}
            onChange={(e) => setNewProduct({ ...newProduct, nutrition: { ...newProduct.nutrition, sugars: e.target.value } })}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Sugars (%)"
            value={newProduct.nutrition.sugarsPercent}
            onChange={(e) => setNewProduct({ ...newProduct, nutrition: { ...newProduct.nutrition, sugarsPercent: e.target.value } })}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Protein"
            value={newProduct.nutrition.protein}
            onChange={(e) => setNewProduct({ ...newProduct, nutrition: { ...newProduct.nutrition, protein: e.target.value } })}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Protein (%)"
            value={newProduct.nutrition.proteinPercent}
            onChange={(e) => setNewProduct({ ...newProduct, nutrition: { ...newProduct.nutrition, proteinPercent: e.target.value } })}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
          //TODO: VITAMIN D CHANGED TO LACTOSE
            label="LACTOSE"
            value={newProduct.nutrition.vitaminD}
            onChange={(e) => setNewProduct({ ...newProduct, nutrition: { ...newProduct.nutrition, vitaminD: e.target.value } })}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Vitamin D (%)"
            value={newProduct.nutrition.vitaminDPercent}
            onChange={(e) => setNewProduct({ ...newProduct, nutrition: { ...newProduct.nutrition, vitaminDPercent: e.target.value } })}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Calcium"
            value={newProduct.nutrition.calcium}
            onChange={(e) => setNewProduct({ ...newProduct, nutrition: { ...newProduct.nutrition, calcium: e.target.value } })}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Calcium (%)"
            value={newProduct.nutrition.calciumPercent}
            onChange={(e) => setNewProduct({ ...newProduct, nutrition: { ...newProduct.nutrition, calciumPercent: e.target.value } })}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Iron"
            value={newProduct.nutrition.iron}
            onChange={(e) => setNewProduct({ ...newProduct, nutrition: { ...newProduct.nutrition, iron: e.target.value } })}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Iron (%)"
            value={newProduct.nutrition.ironPercent}
            onChange={(e) => setNewProduct({ ...newProduct, nutrition: { ...newProduct.nutrition, ironPercent: e.target.value } })}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Potassium"
            value={newProduct.nutrition.potassium}
            onChange={(e) => setNewProduct({ ...newProduct, nutrition: { ...newProduct.nutrition, potassium: e.target.value } })}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Potassium (%)"
            value={newProduct.nutrition.potassiumPercent}
            onChange={(e) => setNewProduct({ ...newProduct, nutrition: { ...newProduct.nutrition, potassiumPercent: e.target.value } })}
            fullWidth
          />
        </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Salt"
            value={newProduct.nutrition.salt}
            onChange={(e) => setNewProduct({ ...newProduct, nutrition: { ...newProduct.nutrition, salt: e.target.value } })}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Lactose REAL"
            value={newProduct.nutrition.lactose}
            onChange={(e) => setNewProduct({ ...newProduct, nutrition: { ...newProduct.nutrition, lactose: e.target.value } })}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Fiber"
            value={newProduct.nutrition.fiber}
            onChange={(e) => setNewProduct({ ...newProduct, nutrition: { ...newProduct.nutrition, fiber: e.target.value } })}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Checkbox
            checked={newProduct.isDiscounted}
            onChange={handleDiscountToggle}
          />
          <Typography variant="body1">Is Discounted</Typography>
        </Grid>

        {newProduct.isDiscounted && (
          <Grid item xs={12} sm={6}>
            <TextField
              label="Discounted Price"
              value={newProduct.discountedPrice}
              onChange={(e) =>
                setNewProduct({ ...newProduct, discountedPrice: e.target.value })
              }
              fullWidth
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Button variant="contained" onClick={addProduct}>Submit</Button>
        </Grid>
      
    </Box>
  );
};

export default AddProduct;
