import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss'; // Importing the CSS file here
import App from './App.tsx';
import axios from 'axios';

// Add screen resolution to headers
axios.defaults.headers.common['X-Screen-Resolution'] = `${window.screen.width}x${window.screen.height}`;
const container = document.getElementById('root');
const root = ReactDOM.createRoot(container!);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
