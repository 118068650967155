import React, { useState } from 'react';
import { Typography, Button, Paper, Checkbox, FormControlLabel, Link } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import './CheckoutPage.scss';

const CheckoutPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  // Retrieve the state passed from DetailsForm
  const { cart, totalAmount, shippingMethod, details, parcelLocation } = location.state || {};

  const API_URL = process.env.REACT_APP_API_URL || 'https://staging.popcornas.lt';

  const [agreedToTerms, setAgreedToTerms] = useState(false);

  const handlePayment = async () => {
    if (!details.name || !details.surname || !details.email || !details.phoneNumber) {
      alert("Please fill out all required customer details.");
      return;
    }

    if (shippingMethod === 'homeDelivery' && (!details.address || !details.city || !details.country || !details.postcode)) {
      alert("Please provide complete address details for home delivery.");
      return;
    }

    if (shippingMethod === 'parcelShop' && (!parcelLocation || !parcelLocation.address || !parcelLocation.city)) {
      alert("Please select a parcel shop location for parcel shop delivery.");
      return;
    }

    try {
      const orderId = `order-${Date.now()}`;
      const orderData = {
        items: cart.map(item => ({
          product: item._id,
          quantity: item.quantity,
        })),
        amount: totalAmount,
        currency: 'EUR',
        orderId,
        description: `Order #${orderId} from Popcornas.lt`,
        customerDetails: {
          name: details.name,
          surname: details.surname,
          email: details.email,
          phoneNumber: details.phoneNumber,
          city: details.city,
          address: details.address,
          postcode: details.postcode,
          country: details.country,
        },
        shippingMethod: shippingMethod,
        parcelLocation: parcelLocation ? `${parcelLocation.city}, ${parcelLocation.address} - ${parcelLocation.name}` : null,
      };

      const orderResponse = await axios.post(`${API_URL}/api/orders`, orderData);
      const paymentData = {
        amount: totalAmount * 100,
        currency: 'EUR',
        orderId: orderResponse.data.orderId,
        description: `Order #${orderId} from Popcornas.lt`,
      };

      const paymentResponse = await axios.post(`${API_URL}/api/paysera/payment`, paymentData);

      window.location.href = paymentResponse.data.paymentUrl;
    } catch (error) {
      console.error('Error processing order or payment:', error);
      alert('Failed to process the order or payment.');
    }
  };

  return (
    <div className="checkout-page">
      <Paper elevation={3} className="checkout-container">
        <Typography variant="h4" gutterBottom className="checkout-title">
          {t('Checkout')}
        </Typography>

        <Typography variant="h5" gutterBottom className="checkout-total">
          {t('Total')}: €{totalAmount.toFixed(2)}
        </Typography>

        <Typography variant="body1" gutterBottom>
          {t('Name')}: {details.name} {details.surname}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {t('Email')}: {details.email}
        </Typography>

        {shippingMethod === 'parcelShop' && parcelLocation ? (
          <>
            <Typography variant="body1" gutterBottom>
              {t('ParcelShop')}: {parcelLocation.name}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {t('Address')}: {parcelLocation.city}, {parcelLocation.address}
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="body1" gutterBottom>
              {t('Address')}: {details.address}, {details.city}, {details.country} - {details.postcode}
            </Typography>
          </>
        )}

        <Typography variant="body1" gutterBottom>
          {t('Phone')}: {details.phoneNumber}
        </Typography>

        {/* Checkbox for agreeing with Privacy Policy and Rules */}
        <FormControlLabel
          control={
            <Checkbox
              checked={agreedToTerms}
              onChange={(e) => setAgreedToTerms(e.target.checked)}
              color="primary"
            />
          }
          label={
            <>
              {t('Bypressingthis')}{' '}
              <Link href="/privacy-policy" target="_blank" rel="noopener">
                {t('Privatumo Politika')}
              </Link>{' '}
              {t('ir')}{' '}
              <Link href="/rules" target="_blank" rel="noopener">
                {t('Taisyklėmis')}
              </Link>.
            </>
          }
        />

        {/* Centered Paysera Logo */}
        <div className="paysera-logo-container">
          <img
            src="https://bank.paysera.com/assets/image/payment_types/wallet.png"
            alt="Paysera"
            className="paysera-logo"
          />
        </div>

        <Button
          variant="contained"
          className="checkout-button"
          onClick={handlePayment}
          disabled={!agreedToTerms} // Disable button until terms are agreed to
        >
          {t('ConfirmPayment')}
        </Button>
      </Paper>
    </div>
  );
};

export default CheckoutPage;
