// AdminPage.tsx
import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Tabs, Tab } from '@mui/material';
import OrdersManagement from './OrdersManagement.tsx';
import AddProduct from './AddProduct.tsx';
import EditProduct from './EditProduct.tsx';
import ContactMessages from './ContactMessages.tsx';
import CombinedAnalytics from './CombinedAnalytics.tsx';
import FetchTerminals from './FetchTerminals.tsx';

const AdminPage: React.FC = () => {
  // State to handle login
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState('');

  // State to handle tabs
  const [tabIndex, setTabIndex] = useState(0);

  // Hardcoded password (You should replace this with a more secure mechanism)
  const ADMIN_PASSWORD = 'admin242wqw4'; 

  const handleLogin = () => {
    if (password === ADMIN_PASSWORD) {
      setIsAuthenticated(true);
    } else {
      alert('Incorrect password. Please try again.');
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <Box sx={{ padding: 4, paddingTop: 12.5 }}> {/* Added paddingTop of 100px (12.5 * 8px) */}
      {!isAuthenticated ? (
        <Box sx={{ textAlign: 'center', marginTop: 4 }}>
          <Typography variant="h5" component="div" sx={{ marginBottom: 2 }}>
            Admin Login
          </Typography>
          <TextField
            label="Enter Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            sx={{ maxWidth: 300, margin: '0 auto 16px auto' }}
          />
          <Button variant="contained" onClick={handleLogin}>
            Login
          </Button>
        </Box>
      ) : (
        <>
          <Typography variant="h4" component="div" sx={{ textAlign: 'center', margin: 2 }}>
            Admin Panel
          </Typography>

          {/* Tabs */}
          <Tabs value={tabIndex} onChange={handleTabChange} centered>
            <Tab label="Orders Management" />
            <Tab label="Add Product" />
            <Tab label="Edit Product" />
            <Tab label="Contact Messages" />
            <Tab label="Combined Analytics" />
            <Tab label="Fetch terminlas" />

            {/* Add more tabs as needed */}
          </Tabs>

          {/* Tab Content */}
          {tabIndex === 0 && <OrdersManagement />}
          {tabIndex === 1 && <AddProduct />}
          {tabIndex === 2 && <EditProduct />}
          {tabIndex === 3 && <ContactMessages />}
          {tabIndex === 4 && <CombinedAnalytics />}
          {tabIndex === 5 && <FetchTerminals />}

          {/* Add more tab contents as needed */}

        </>
      )}
    </Box>
  );
};

export default AdminPage;
