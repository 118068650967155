import React, { useState } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  Button,
  IconButton,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { Add, Remove, Delete } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Cart.scss';

interface CartItem {
  _id: number;
  name: string;
  price: number;
  quantity: number;
  images?: string[];
  image?: string;
}

interface CartProps {
  cart: CartItem[];
  setCart: React.Dispatch<React.SetStateAction<CartItem[]>>;
}

const Cart: React.FC<CartProps> = ({ cart, setCart }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [shippingMethod, setShippingMethod] = useState<string>('parcel-shop');

  // Calculate the total amount with pair discount logic across items with price 9.99
  const calculateTotalWithDiscount = () => {
    let total = 0;
    let discountedItemsQuantity = 0;
    let discountAmount = 0;

    cart.forEach((item) => {
      if (item.price === 9.99) {
        discountedItemsQuantity += item.quantity; // Add quantity for items priced at 9.99
      } else {
        total += item.price * item.quantity; // Add items that do not qualify for discount
      }
    });

    // Apply discount for items with price 9.99 in pairs
    const pairs = Math.floor(discountedItemsQuantity / 2);
    const remainder = discountedItemsQuantity % 2;

    discountAmount = pairs * (2 * 9.99 - 15); // Total discount for all pairs
    total += pairs * 15 + remainder * 9.99;

    if (shippingMethod === 'courier') {
      total += 2.5;
    }

    return { total, discountAmount };
  };

  const { total, discountAmount } = calculateTotalWithDiscount();

  const handleIncreaseQuantity = (id: number) => {
    setCart(
      cart.map((item) =>
        item._id === id ? { ...item, quantity: item.quantity + 1 } : item
      )
    );
  };

  const handleDecreaseQuantity = (id: number) => {
    setCart(
      cart
        .map((item) =>
          item._id === id ? { ...item, quantity: item.quantity - 1 } : item
        )
        .filter((item) => item.quantity > 0)
    );
  };

  const handleRemoveItem = (id: number) => {
    setCart(cart.filter((item) => item._id !== id));
  };

  const handleCheckout = () => {
    if (shippingMethod === 'parcel-shop') {
      navigate('/parcel-shop', { state: { cart, totalAmount: total, shippingMethod } });
    } else {
      navigate('/details', { state: { cart, totalAmount: total, shippingMethod } });
    }
  };

  const handleShippingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShippingMethod(event.target.value);
  };

  return (
    <div className="cart-container">
      <Typography variant="h4" gutterBottom className="cart-heading">
        {t('yourCart')}
      </Typography>

      <List className="cart-list">
        {cart.map((item) => {
          const defaultImage = "https://i.ibb.co/0fC2XKJ/2024-09-10-22-22-03.jpg";
          const displayedImage = item.images && item.images.length > 0 ? item.images[0] : item.image || defaultImage;

          return (
            <div key={item._id}>
              <ListItem className="cart-item">
                <img
                  src={displayedImage}
                  alt={item.name}
                  className="cart-item-image"
                />
                <ListItemText
                  primary={<Typography className="item-name">{item.name}</Typography>}
                  secondary={
                    <Typography className="item-details" sx={{ color: '#fff', fontSize: '1rem', fontWeight: 500 }}>
                      {`${t('price')}: €${item.price.toFixed(2)} | ${t('quantity')}: ${item.quantity}`}
                    </Typography>
                  }
                />
                <div className="cart-item-actions">
                  <IconButton
                    onClick={() => handleDecreaseQuantity(item._id)}
                    className="quantity-btn"
                  >
                    <Remove />
                  </IconButton>
                  <IconButton
                    onClick={() => handleIncreaseQuantity(item._id)}
                    className="quantity-btn"
                  >
                    <Add />
                  </IconButton>
                  <IconButton
                    onClick={() => handleRemoveItem(item._id)}
                    className="delete-btn"
                  >
                    <Delete />
                  </IconButton>
                </div>
                <Typography variant="body1" className="item-total">
                  €{(item.price * item.quantity).toFixed(2)}
                </Typography>
              </ListItem>
              <Divider className="divider" />
            </div>
          );
        })}
      </List>

      {/* Shipping Options */}
      <FormControl component="fieldset" className="shipping-options">
        <Typography variant="h6" className="shipping-heading">
          {t('chooseShippingMethod')}
        </Typography>
        <RadioGroup value={shippingMethod} onChange={handleShippingChange}>
          <FormControlLabel
            value="parcel-shop"
            control={<Radio />}
            label={t('parcelShop')}
            className="shipping-option"
          />
          <FormControlLabel
            value="courier"
            control={<Radio />}
            label={`${t('courier')} (+€2.50)`}
            className="shipping-option"
          />
        </RadioGroup>
      </FormControl>

      <Typography variant="h5" gutterBottom className="cart-total">
        {t('total')}: <span className="total-amount">€{total.toFixed(2)}</span>
      </Typography>

      {discountAmount > 0 && (
        <Typography variant="body1" className="cart-discount">
          {t('discountApplied')}: <span className="discount-amount">-€{discountAmount.toFixed(2)}</span>
        </Typography>
      )}

      <Button variant="contained" className="checkout-btn" onClick={handleCheckout}>
        {t('proceedToCheckout')}
      </Button>
    </div>
  );
};

export default Cart;
