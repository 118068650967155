import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography, TextField, Grid, Button, IconButton, Collapse, Paper } from '@mui/material';
import { Edit, Save, Cancel, Delete } from '@mui/icons-material';

const BASE_URL = 'http://localhost:5001'; // Adjust this URL as per your API configuration
const API_URL = process.env.REACT_APP_API_URL || 'https://staging.popcornas.lt';

interface Product {
  _id: string;
  name: string;
  price: string;
  images: string[];
  description: string;
  category: string;
  composition: string;
  nutrition: {
    [key: string]: any; // Adjusted to simplify handling dynamic nutrition properties
  };
}

const EditProducts: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [editingProductId, setEditingProductId] = useState<string | null>(null);
  const [editableProduct, setEditableProduct] = useState<Product | null>(null);

  useEffect(() => {
    axios.get(`${API_URL}/api/products`)
      .then((response) => {
        setProducts(response.data);
      })
      .catch((error) => {
        console.error('Error fetching products:', error);
      });
  }, []);

  const startEditing = (product: Product) => {
    const completeNutrition = {
      servingSize: '',
      calories: '',
      caloriesKj: '',
      totalFat: '',
      totalFatPercent: '',
      saturatedFat: '',
      saturatedFatPercent: '',
      cholesterol: '',
      cholesterolPercent: '',
      sodium: '',
      sodiumPercent: '',
      totalCarbohydrate: '',
      totalCarbohydratePercent: '',
      dietaryFiber: '',
      dietaryFiberPercent: '',
      sugars: '',
      sugarsPercent: '',
      protein: '',
      proteinPercent: '',
      vitaminD: '',
      vitaminDPercent: '',
      calcium: '',
      calciumPercent: '',
      iron: '',
      ironPercent: '',
      potassium: '',
      potassiumPercent: '',
      salt:'',
      lactose: '',
      fiber:'',
      ...product.nutrition,
    };
    setEditingProductId(product._id);
    setEditableProduct({ ...product, nutrition: completeNutrition });
  };

  const cancelEditing = () => {
    setEditingProductId(null);
    setEditableProduct(null);
  };

  const saveProduct = async () => {
    try {
      if (editableProduct) {
        await axios.put(`${API_URL}/api/products/${editableProduct._id}`, editableProduct);
        setProducts(products.map((product) =>
          product._id === editableProduct._id ? editableProduct : product
        ));
        cancelEditing();
        alert('Product updated successfully');
      }
    } catch (error) {
      console.error('Error updating product:', error);
      alert('Error updating product');
    }
  };

  const deleteProduct = async (productId: string) => {
    if (window.confirm('Are you sure you want to delete this product?')) {
      try {
        await axios.delete(`${API_URL}/api/products/${productId}`);
        setProducts(products.filter(product => product._id !== productId));
        alert('Product deleted successfully');
      } catch (error) {
        console.error('Error deleting product:', error);
        alert('Error deleting product');
      }
    }
  };

  const handleChange = (field: string, value: any) => {
    if (editableProduct) {
      setEditableProduct({ ...editableProduct, [field]: value });
    }
  };

  const handleNutritionChange = (field: string, value: any) => {
    if (editableProduct) {
      setEditableProduct({
        ...editableProduct,
        nutrition: { ...editableProduct.nutrition, [field]: value },
      });
    }
  };

  const handleImageChange = (index: number, value: string) => {
    if (editableProduct) {
      const updatedImages = [...editableProduct.images];
      updatedImages[index] = value;
      setEditableProduct({ ...editableProduct, images: updatedImages });
    }
  };

  const addImage = () => {
    if (editableProduct) {
      setEditableProduct({ ...editableProduct, images: [...editableProduct.images, ''] });
    }
  };

  const removeImage = (index: number) => {
    if (editableProduct) {
      const updatedImages = editableProduct.images.filter((_, i) => i !== index);
      setEditableProduct({ ...editableProduct, images: updatedImages });
    }
  };

  return (
    <Box>
      <Typography variant="h5" component="div" sx={{ textAlign: 'center', margin: 2 }}>
        Edit Products
      </Typography>
      {products.length === 0 ? (
        <Typography variant="body1" component="div" sx={{ textAlign: 'center', margin: 2 }}>
          No products available.
        </Typography>
      ) : (
        products.map((product) => (
          <Paper key={product._id} sx={{ padding: 2, marginBottom: 2 }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={2}>
                <img
                  src={product.images[0] || 'https://via.placeholder.com/100'} // Display placeholder if no image
                  alt={product.name}
                  style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '8px' }}
                />
              </Grid>
              <Grid item xs={2}>
                {editingProductId === product._id ? (
                  <TextField
                    label="Product Name"
                    value={editableProduct?.name || ''}
                    onChange={(e) => handleChange('name', e.target.value)}
                    fullWidth
                  />
                ) : (
                  <Typography variant="body1">{product.name}</Typography>
                )}
              </Grid>
              <Grid item xs={2}>
                {editingProductId === product._id ? (
                  <TextField
                    label="Price"
                    value={editableProduct?.price || ''}
                    onChange={(e) => handleChange('price', e.target.value)}
                    fullWidth
                  />
                ) : (
                  <Typography variant="body1">€{product.price}</Typography>
                )}
              </Grid>
              <Grid item xs={2}>
                {editingProductId === product._id ? (
                  <TextField
                    label="Category"
                    value={editableProduct?.category || ''}
                    onChange={(e) => handleChange('category', e.target.value)}
                    fullWidth
                  />
                ) : (
                  <Typography variant="body1">{product.category}</Typography>
                )}
              </Grid>
              <Grid item xs={4}>
                {editingProductId === product._id ? (
                  <Box display="flex" gap={1}>
                    <IconButton onClick={saveProduct}>
                      <Save />
                    </IconButton>
                    <IconButton onClick={cancelEditing}>
                      <Cancel />
                    </IconButton>
                  </Box>
                ) : (
                  <Box display="flex" gap={1}>
                    <Button variant="outlined" onClick={() => startEditing(product)}>
                      Edit
                    </Button>
                    <IconButton color="secondary" onClick={() => deleteProduct(product._id)}>
                      <Delete />
                    </IconButton>
                  </Box>
                )}
              </Grid>
            </Grid>

            <Collapse in={editingProductId === product._id} timeout="auto" unmountOnExit>
              <Box sx={{ marginTop: 2 }}>
                <Typography variant="subtitle1" gutterBottom>
                  Edit Details
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      label="Description"
                      value={editableProduct?.description || ''}
                      onChange={(e) => handleChange('description', e.target.value)}
                      fullWidth
                      multiline
                      rows={3}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Composition"
                      value={editableProduct?.composition || ''}
                      onChange={(e) => handleChange('composition', e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">Images</Typography>
                    {editableProduct?.images.map((image, index) => (
                      <Box key={index} display="flex" alignItems="center" gap={1} sx={{ marginBottom: 1 }}>
                        <TextField
                          label={`Image URL ${index + 1}`}
                          value={image}
                          onChange={(e) => handleImageChange(index, e.target.value)}
                          fullWidth
                        />
                        <Button variant="outlined" color="error" onClick={() => removeImage(index)}>
                          Remove
                        </Button>
                      </Box>
                    ))}
                    <Button variant="contained" onClick={addImage}>
                      Add Image
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">Nutrition Information</Typography>
                  </Grid>
                  {editableProduct?.nutrition &&
                    Object.keys(editableProduct.nutrition).map((key) => (
                      <Grid item xs={6} key={key}>
                        <TextField
                          label={key.replace(/([A-Z])/g, ' $1')}
                          value={editableProduct?.nutrition[key] || ''}
                          onChange={(e) => handleNutritionChange(key, e.target.value)}
                          fullWidth
                        />
                      </Grid>
                    ))}
                </Grid>
              </Box>
            </Collapse>
          </Paper>
        ))
      )}
    </Box>
  );
};

export default EditProducts;
