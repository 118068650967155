// CombinedAnalytics.tsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Typography, Paper, CircularProgress, Select, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import styles from './CombinedAnalytics.module.scss'; // Import SCSS module

const BASE_URL = 'http://localhost:5001';
const API_URL = process.env.REACT_APP_API_URL || 'https://staging.popcornas.lt';

interface AnalyticsData {
  _id: string;
  ip: string;
  userAgent: string;
  url: string;
  referrer: string;
  timestamp: string;
}

interface FingerprintData {
  _id: string;
  ip: string;
  browser: string;
  browserVersion: string;
  os: string;
  osVersion: string;
  device: string;
  language: string;
  screenResolution: string;
  timestamp: string;
}

const CombinedAnalytics: React.FC = () => {
  const [analyticsData, setAnalyticsData] = useState<AnalyticsData[]>([]);
  const [fingerprintData, setFingerprintData] = useState<FingerprintData[]>([]);
  const [loading, setLoading] = useState(true);
  const [timeRange, setTimeRange] = useState('24h');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [analyticsRes, fingerprintRes] = await Promise.all([
          axios.get(`${API_URL}/api/analytics`),
          axios.get(`${API_URL}/api/fingerprints`),
        ]);
        setAnalyticsData(analyticsRes.data);
        setFingerprintData(fingerprintRes.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const filterDataByTimeRange = (data: any[], range: string) => {
    const now = new Date().getTime();
    const ranges = {
      '1h': 60 * 60 * 1000,
      '6h': 6 * 60 * 60 * 1000,
      '24h': 24 * 60 * 60 * 1000,
      '5d': 5 * 24 * 60 * 60 * 1000,
      '30d': 30 * 24 * 60 * 60 * 1000,
    };
    const rangeInMs = ranges[range];
    return data.filter(entry => new Date(entry.timestamp).getTime() >= now - rangeInMs);
  };

  const filteredAnalyticsData = filterDataByTimeRange(analyticsData, timeRange);
  const filteredFingerprintData = filterDataByTimeRange(fingerprintData, timeRange);

  // Prepare data for the main line chart
  const prepareChartData = () => {
    const dataByDate: { [date: string]: { analytics: number; fingerprints: number } } = {};
    filteredAnalyticsData.forEach(entry => {
      const date = new Date(entry.timestamp).toLocaleString();
      if (!dataByDate[date]) dataByDate[date] = { analytics: 0, fingerprints: 0 };
      dataByDate[date].analytics += 1;
    });

    filteredFingerprintData.forEach(entry => {
      const date = new Date(entry.timestamp).toLocaleString();
      if (!dataByDate[date]) dataByDate[date] = { analytics: 0, fingerprints: 0 };
      dataByDate[date].fingerprints += 1;
    });

    return Object.entries(dataByDate).map(([date, counts]) => ({
      date,
      analytics: counts.analytics,
      fingerprints: counts.fingerprints,
    }));
  };

  // Prepare data for the pie charts
  const prepareDeviceDistributionData = () => {
    const deviceCounts: { [device: string]: number } = {};
    filteredFingerprintData.forEach(entry => {
      const device = entry.device || 'Unknown';
      deviceCounts[device] = (deviceCounts[device] || 0) + 1;
    });
    return Object.entries(deviceCounts).map(([device, count]) => ({
      name: device,
      value: count,
    }));
  };

  const prepareScreenResolutionData = () => {
    const resolutionCounts: { [resolution: string]: number } = {};
    filteredFingerprintData.forEach(entry => {
      const resolution = entry.screenResolution || 'Unknown';
      resolutionCounts[resolution] = (resolutionCounts[resolution] || 0) + 1;
    });
    return Object.entries(resolutionCounts).map(([resolution, count]) => ({
      name: resolution,
      value: count,
    }));
  };

  const prepareLanguageData = () => {
    const languageCounts: { [language: string]: number } = {};
    filteredFingerprintData.forEach(entry => {
      const language = entry.language || 'Unknown';
      languageCounts[language] = (languageCounts[language] || 0) + 1;
    });
    return Object.entries(languageCounts).map(([language, count]) => ({
      name: language,
      value: count,
    }));
  };

  const prepareOSVersionData = () => {
    const osVersionCounts: { [osVersion: string]: number } = {};
    filteredFingerprintData.forEach(entry => {
      const osVersion = entry.osVersion || 'Unknown';
      osVersionCounts[osVersion] = (osVersionCounts[osVersion] || 0) + 1;
    });
    return Object.entries(osVersionCounts).map(([osVersion, count]) => ({
      name: osVersion,
      value: count,
    }));
  };

  const chartData = prepareChartData();
  const deviceDistributionData = prepareDeviceDistributionData();
  const screenResolutionData = prepareScreenResolutionData();
  const languageData = prepareLanguageData();
  const osVersionData = prepareOSVersionData();

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AF19FF', '#FF5757', '#82ca9d'];

  return (
    <Box className={styles.container}>
      <Typography variant="h4">Combined Analytics Dashboard</Typography>

      {loading ? (
        <Box sx={{ textAlign: 'center', margin: 2 }}>
          <CircularProgress />
          <Typography variant="body1">Loading analytics data...</Typography>
        </Box>
      ) : (
        <>
          {/* Time Range Selector */}
          <Box className={styles.timeRangeSelector}>
            <Typography variant="h6">Select Time Range:</Typography>
            <Select
              value={timeRange}
              onChange={(e) => setTimeRange(e.target.value)}
              className={styles.selectBox}
            >
              <MenuItem value="1h">1 Hour</MenuItem>
              <MenuItem value="6h">6 Hours</MenuItem>
              <MenuItem value="24h">24 Hours</MenuItem>
              <MenuItem value="5d">5 Days</MenuItem>
              <MenuItem value="30d">30 Days</MenuItem>
            </Select>
          </Box>

          {/* Line chart for analytics over time */}
          <Box className={styles.chartWrapper}>
            <Typography variant="h6">Web & Fingerprint Analytics Over Time</Typography>
            <ResponsiveContainer className={styles.chart}>
              <LineChart data={chartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="analytics" stroke="#8884d8" name="Page Views" />
                <Line type="monotone" dataKey="fingerprints" stroke="#82ca9d" name="Fingerprint Data" />
              </LineChart>
            </ResponsiveContainer>
          </Box>

          {/* Pie charts for device distribution, screen resolution, language, and OS version */}
          <Box className={styles.chartWrapper}>
            <Typography variant="h6">Fingerprint Device Distribution</Typography>
            <ResponsiveContainer className={styles.chart}>
              <PieChart>
                <Pie data={deviceDistributionData} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={150} fill="#82ca9d" label>
                  {deviceDistributionData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </Box>

          <Box className={styles.chartWrapper}>
            <Typography variant="h6">Screen Resolution Distribution</Typography>
            <ResponsiveContainer className={styles.chart}>
              <PieChart>
                <Pie data={screenResolutionData} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={150} fill="#8884d8" label>
                  {screenResolutionData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </Box>

          <Box className={styles.chartWrapper}>
            <Typography variant="h6">Language Distribution</Typography>
            <ResponsiveContainer className={styles.chart}>
              <PieChart>
                <Pie data={languageData} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={150} fill="#FF8042" label>
                  {languageData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </Box>

          <Box className={styles.chartWrapper}>
            <Typography variant="h6">Operating System Version Distribution</Typography>
            <ResponsiveContainer className={styles.chart}>
              <PieChart>
                <Pie data={osVersionData} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={150} fill="#FFBB28" label>
                  {osVersionData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </Box>

          {/* Combined analytics data table */}
          <Box className={styles.tableContainer}>
            <Paper className={styles.tableWrapper}>
              <Typography variant="h6">Combined Analytics Data</Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Page Views</TableCell>
                      <TableCell>Fingerprint Entries</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {chartData.map((data, index) => (
                      <TableRow key={index}>
                        <TableCell>{data.date}</TableCell>
                        <TableCell>{data.analytics}</TableCell>
                        <TableCell>{data.fingerprints}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </>
      )}
    </Box>
  );
};

export default CombinedAnalytics;

