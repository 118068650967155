import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography, TextField, Grid, Button, Divider, Select, MenuItem, FormControl, InputLabel, Card, CardMedia, CardContent, Pagination,  List, ListItem, ListItemText  } from '@mui/material';
import { format, subDays } from 'date-fns';  // To format the date and calculate date ranges

const BASE_URL = 'http://localhost:5001'; // Adjust this URL as per your API configuration
const API_URL = process.env.REACT_APP_API_URL || 'https://staging.popcornas.lt';

// Placeholder image URL
const placeholderImage = 'https://www.angolo-creativo.it/wp-content/uploads/2017/10/spezie-bomboniera-segnaposto.jpg';

const OrdersManagement: React.FC = () => {
  const [orders, setOrders] = useState<any[]>([]);
  const [trackingNumbers, setTrackingNumbers] = useState<{ [key: string]: string }>({});
  const [orderStatuses, setOrderStatuses] = useState<{ [key: string]: string }>({});
  const [searchTerm, setSearchTerm] = useState(''); 
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5); 
  const [sortStatus, setSortStatus] = useState(''); 
  const [timePeriod, setTimePeriod] = useState('lastWeek'); // Default time period filter

  useEffect(() => {
    axios.get(`${API_URL}/api/orders`)
      .then((response) => {
        const ordersData = response.data.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()); // Newest first
        setOrders(ordersData);

        const initialTrackingNumbers = ordersData.reduce((acc: any, order: any) => {
          acc[order._id] = order.trackingNumber || '';
          return acc;
        }, {});

        const initialOrderStatuses = ordersData.reduce((acc: any, order: any) => {
          acc[order._id] = order.orderStatus || 'Pending';
          return acc;
        }, {});

        setTrackingNumbers(initialTrackingNumbers);
        setOrderStatuses(initialOrderStatuses);
      })
      .catch((error) => {
        console.error('Error fetching orders:', error);
      });
  }, []);

  const updateOrder = (orderId: string) => {
    const trackingNumber = trackingNumbers[orderId];
    const orderStatus = orderStatuses[orderId];

    axios.post(`${API_URL}/api/update-order`, { orderId, trackingNumber, orderStatus })
      .then(() => {
        alert('Order updated');
        setOrders(orders.map(order => order._id === orderId ? { ...order, trackingNumber, orderStatus } : order));
      })
      .catch((error) => {
        console.error('Error updating order:', error);
      });
  };

  const handleTrackingNumberChange = (orderId: string, value: string) => {
    setTrackingNumbers({ ...trackingNumbers, [orderId]: value });
  };

  const handleOrderStatusChange = (orderId: string, value: string) => {
    setOrderStatuses({ ...orderStatuses, [orderId]: value });
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSortStatusChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSortStatus(event.target.value as string);
  };

  const handleTimePeriodChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTimePeriod(event.target.value as string);
  };

  // Get the start date for filtering based on the selected time period
  const getStartDateForPeriod = () => {
    const now = new Date();
    switch (timePeriod) {
      case 'lastWeek':
        return subDays(now, 7);  // 7 days ago
      case 'lastMonth':
        return subDays(now, 30);  // 30 days ago
      case 'last3Months':
        return subDays(now, 90);  // 90 days ago
      default:
        return subDays(now, 7);  // Default to last week
    }
  };

  // Filter orders by search term, status, and time period
  const filteredOrders = orders
    .filter(order => {
      const orderDate = new Date(order.createdAt);
      const startDate = getStartDateForPeriod();
      return (
        (orderDate >= startDate) &&  // Filter by time period
        (order?.customerName?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
        order?.customerSurname?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
        order?._id?.toLowerCase()?.includes(searchTerm.toLowerCase())) &&
        (sortStatus === '' || order.orderStatus === sortStatus)
      );
    });

  // Pagination logic
  const indexOfLastOrder = currentPage * itemsPerPage;
  const indexOfFirstOrder = indexOfLastOrder - itemsPerPage;
  const currentOrders = filteredOrders.slice(indexOfFirstOrder, indexOfLastOrder);

  return (
    <Box>
      <Typography variant="h5" component="div" sx={{ textAlign: 'center', margin: 2 }}>
        Orders Management
      </Typography>

      {/* Search Bar */}
      <Box sx={{ textAlign: 'center', marginBottom: 4 }}>
        <TextField
          label="Search by Customer Name or Order ID"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
          fullWidth
        />
      </Box>

      {/* Sort by Order Status */}
      <Box sx={{ textAlign: 'center', marginBottom: 4 }}>
        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel>Sort by Status</InputLabel>
          <Select value={sortStatus} onChange={handleSortStatusChange}>
            <MenuItem value="">All</MenuItem>
            <MenuItem value="Pending">Pending</MenuItem>
            <MenuItem value="Paid">Paid</MenuItem>
            <MenuItem value="Shipped">Shipped</MenuItem>
            <MenuItem value="Delivered">Delivered</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {/* Filter by Time Period */}
      <Box sx={{ textAlign: 'center', marginBottom: 4 }}>
        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel>Time Period</InputLabel>
          <Select value={timePeriod} onChange={handleTimePeriodChange}>
            <MenuItem value="lastWeek">Last Week</MenuItem>
            <MenuItem value="lastMonth">Last Month</MenuItem>
            <MenuItem value="last3Months">Last 3 Months</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {currentOrders.length === 0 ? (
        <Typography variant="body1" component="div" sx={{ textAlign: 'center', margin: 2 }}>
          No orders match your search.
        </Typography>
      ) : (
        currentOrders.map((order) => (
          <Box
            key={order?._id || 'unknown-order-id'}
            sx={{ marginBottom: 4, border: order.orderStatus === 'Paid' ? '2px solid green' : 'none' }} // Paid order border
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4}>
                <Typography variant="body1">Order ID: {order?._id || "Unknown Order ID"}</Typography>
                
                <Typography variant="body2">
                  Customer: {order?.customerName || "Unknown"} {order?.customerSurname || "Unknown"}
                </Typography>
                <Typography variant="body2">
                  Address: {order?.parcelLocation || "Unknown Address"}, {order?.city || "Unknown City"}, {order?.customerEmail || "Unknown State"}, {order?.phoneNumber || "Unknown Zip"}
                </Typography>
                <Typography variant="body2">
                  Method: {order?.shippingMethod || "Unknown Address"}, {order?.postcode || "Unknown City"}, {order?.amount+"EUR" || "Unknown State"}
                </Typography>
                <Typography variant="body2">
                  Status: {orderStatuses[order?._id] || 'Pending'}
                </Typography>
                <Typography variant="body2">
                  Created At: {order?.createdAt ? format(new Date(order.createdAt), 'dd/MM/yyyy HH:mm') : "Unknown Date"}
                </Typography>
                <Typography variant="h6" sx={{ mt: 2 }}>Order Items:</Typography>
                <List>
  {order.items && order.items.length > 0 ? (
    order.items.map((item: any, index: number) => (
      <ListItem key={index}>
        {item.product ? (
          <ListItemText
            primary={`Product Name: ${item.product.name || 'Unknown'}`} // Display product name if available
            secondary={`Quantity: ${item.quantity}`}
          />
        ) : (
          <ListItemText
            primary="Product details unavailable"
            secondary={`Quantity: ${item.quantity}`}
          />
        )}
      </ListItem>
    ))
  ) : (
    <Typography variant="body2">No items found in this order.</Typography>
  )}
</List>

              </Grid>

              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel id={`order-status-label-${order?._id}`}>Order Status</InputLabel>
                  <Select
                    labelId={`order-status-label-${order?._id}`}
                    value={orderStatuses[order?._id] || ''}
                    label="Order Status"
                    onChange={(e) => handleOrderStatusChange(order?._id || '', e.target.value)}
                  >
                    <MenuItem value="Pending">Pending</MenuItem>
                    <MenuItem value="Paid">Paid</MenuItem>
                    <MenuItem value="Shipped">Shipped</MenuItem>
                    <MenuItem value="Delivered">Delivered</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={3}>
                <TextField
                  label="Tracking Number"
                  value={trackingNumbers[order?._id] || ''}
                  onChange={(e) => handleTrackingNumberChange(order?._id || '', e.target.value)}
                  fullWidth
                />
              </Grid>

              <Grid item xs={2}>
                <Button variant="contained" onClick={() => updateOrder(order?._id || '')}>Update</Button>
              </Grid>
            </Grid>

            <Divider sx={{ my: 4 }} />
          </Box>
        ))
      )}

      {/* Pagination Controls */}
      <Box sx={{ textAlign: 'center', marginTop: 4 }}>
        <Pagination
          count={Math.ceil(filteredOrders.length / itemsPerPage)}
          page={currentPage}
          onChange={(event, value) => setCurrentPage(value)}
        />
      </Box>
    </Box>
  );
};

export default OrdersManagement;
